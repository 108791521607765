<script setup lang="ts">
const route = useRoute();

const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const href = computed(() => {
    const link = props.blok.link;
    const market: Market = route.query?.market;

    if (market) {
        return `${link.cached_url}?market=${route.query.market}`;
    } else {
        return link.cached_url;
    }
});
</script>

<template>
    <li>
        <a
            :href="href"
            class="flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
        >
            <div class="p-2 mr-4 bg-gray-500 rounded-lg shadow dark:bg-gray-700">
                <img :src="blok.icon.filename" :alt="blok.icon.alt" class="max-w-none w-5 h-5" />
            </div>
            <div>
                <div class="font-semibold">{{ blok.title }}</div>
                <div class="text-sm font-light text-gray-500 dark:text-gray-400">
                    {{ blok.text }}
                </div>
            </div>
        </a>
    </li>
</template>
